import React from 'react';
import { useTranslation } from 'react-i18next';

function Cards() {
  const { t } = useTranslation(); // Use the translation hook

  return (
    <>
      <div className="w-full flex justify-center">
        <div className="grid  w-11/12 gap-7 grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-3">
          <div className="py-[20px] border-[#8a8b8e] border-1 rounded-xl">
            <div className="">
              <h1 className="font-intertight leading-[89.54px] font-black text-center text-74 text-darkstate">
                01
              </h1>
              <h2 className="font-intertight mt-20 font-normal leading-[26.62px] text-22 text-center text-richblack">
                {t('Sign Up')}
              </h2>
              <p className="font-intertight font-light mt-2.5 text-sm text-battlegrey text-center mx-auto w-[253px]">
                {t('Complete our partner application form and we will get in touch with you.')}
              </p>
            </div>
          </div>
          <div className="py-[20px] border-[#8a8b8e] border-1 rounded-xl">
            <div className="">
              <h1 className="font-intertight leading-[89.54px] font-black text-center text-74 text-darkstate">
                02
              </h1>
              <h2 className="font-intertight mt-20 text-22 text-center font-normal text-richblack">
                {t('Profile Set Up')}
              </h2>
              <p className="font-intertight font-light mt-2.5 text-sm text-battlegrey text-center mx-auto w-[253px]">
                {t('Provide details about your venue and set up your profile in the Partner Portal.')}
              </p>
            </div>
          </div>
          <div className="py-[20px]  border-[#8a8b8e] border-1 rounded-xl">
            <div className="">
              <h1 className="font-intertight leading-[89.54px] font-black text-center text-74 text-darkstate">
                03
              </h1>
              <h2 className="font-intertight mt-20 mx-4 text-22 text-center font-normal text-richblack">
                {t('Start Receiving Bookings')}
              </h2>
              <p className="font-intertight font-light mt-2.5 text-sm text-battlegrey text-center mx-auto w-[253px]">
                {t('Begin welcoming members from the Alma community.')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cards;
