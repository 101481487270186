import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next'; // Import the translation hook
import background from "../../assets/images/Background.jfif";

import Cards from "./cards";
import BecomePartners from "./becomepartner"; // Assuming you have this component

function Parteners() {
  const { t } = useTranslation(); // Use the translation hook

  // Create a reference to the BecomePartners component
  const becomePartnerRef = useRef(null);

  // Scroll to the BecomePartners component
  const handleScroll = () => {
    becomePartnerRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <div
        className="w-full h-min md:h-810 bg-cover bg-center relative"
        style={{ backgroundImage: `url(${background})` }}
      >
        {/* Overlay */}
        <div className="absolute inset-0 bg-black opacity-25"></div>

        {/* Content */}
        <div className="relative z-10 ml-3 md:ml-7 lg:ml-100 ">
          <h1 className="text-3xl pt-32 md:w-3/4 lg:wd-[664px] md:pt-36 lg:pt-[310px] md:text-5xl lg:text-64 font-intertight md:leading-77.44 tracking-0.015 font-550 text-starwhite ">
            {t('Join the Alma Network')}
          </h1>
          <p className="w-11/12 md:w-3/4 lg:w-719 font-intertight font-light  text-white text-sm md:text-lg lg:text-xl mt-[30px]">
            {t('Become a fitness partner and elevate your business by featuring it on the Almax app, attracting new clients and boosting your earnings without any cost.')}
          </p>
          <button
            className="bg-richblack w-[169.94px] h-[48.95px] mb-[100px] md:mb-0 rounded-full font-intertight font-light text-white text-sm md:text-base mt-8 md:mt-10"
            onClick={handleScroll} // Call handleScroll when button is clicked
          >
            {t('Become a Partner')}
          </button>
        </div>
      </div>
      <div className="bg-white">
        <div className="mt-[80px] mb-[40px] sm:mt-155 sm:mb-85 ">
          <Cards />

          <div ref={becomePartnerRef}>
            <BecomePartners />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Parteners;
