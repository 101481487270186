import React, { useEffect, useState, useMemo } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css'; // Import the dropdown styles
import { useTranslation } from 'react-i18next'; // Import the translation hook

// SVG component for custom arrow
const CustomDropdownArrow = () => (
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1.5L7 8.5L13 1.5" stroke="#DBDCDB" strokeWidth="2" strokeLinecap="round" />
  </svg>
);

function CityDropdown({ selectedCity, handleCityChange }) {
  const { t, i18n } = useTranslation(); // Use the translation hook

  // State to handle placeholder translation
  const [placeholder, setPlaceholder] = useState(t('city'));

  useEffect(() => {
    // Update the placeholder when the language changes
    const handleLanguageChanged = () => {
      setPlaceholder(t('city')); // Update placeholder when language changes
    };

    i18n.on('languageChanged', handleLanguageChanged);

    // Cleanup listener when component unmounts
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [i18n, t]);

  // List of cities with translation keys
  const options = useMemo(() => [
    
    { value: 'Athens', label: t('Athens') },
    { value: 'Thessaloniki', label: t('Thessaloniki') },
    { value: 'Patras', label: t('Patras') },
    { value: 'Heraklion', label: t('Heraklion') },
    { value: 'Larissa', label: t('Larissa') },
    { value: 'Volos', label: t('Volos') },
    { value: 'Ioannina', label: t('Ioannina') },
    { value: 'Chania', label: t('Chania') },
    { value: 'Rhodes', label: t('Rhodes') },
    { value: 'Kavala', label: t('Kavala') },
    { value: 'other', label: t('Other') }
  ], [t]);

  // Handle the city selection
  const handleInputChange = (option) => {
    handleCityChange(option.value);
  };

  return (
    <div className="w-full mt-3">
      {/* Dropdown component */}
      <Dropdown
        options={options}
        onChange={handleInputChange}
        value={selectedCity ? options.find(option => option.value === selectedCity) : null} // No default selection
        placeholder={placeholder} // Dynamically update the placeholder
        className="w-full custom-dropdown"
        arrowClosed={<CustomDropdownArrow />}
        arrowOpen={<CustomDropdownArrow />}
      />
    </div>
  );
}

export default CityDropdown;
