import React from 'react';
import { useTranslation } from 'react-i18next';
import Form from './FormParteners';

function BecomePartner() {
  const { t } = useTranslation(); // Use the translation hook

  return (
    <>
      <div className="flex items-center justify-center">
        <div className="mt-100 w-10/12 md:w-2/3 text-center">
          <h2 className="font-intertight font-medium leading-[48.4px] text-40">
            {t('Partner Application Form')}
          </h2>
          <p className="font-intertight text-darkstate leading-[24.2px] mt-2.5 text-20 font-extralight">
            {t('Fill in a quick form to become our partner')}
          </p>
          <div>
            <Form />
          </div>
        </div>
      </div>
    </>
  );
}

export default BecomePartner;
