import React from 'react';
import { useTranslation } from 'react-i18next';
import mobile from '../../assets/images/mobile1.png';
import homemobile from '../../assets/images/homemobile.png';
import ticksvg from '../../assets/svg/Checkmark.svg';

function Benefits() {
  const { t } = useTranslation(); // Use the translation hook

  return (
    <>
      <div className="relative">
        <div className="absolute inset-0 bg-battleshipgrey opacity-10 z-0"></div>
        <div className="relative z-10">
          <h1 className="font-intertight pt-63 font-medium text-center text-xl sm:text-xl md:text-2xl lg:text-40">
            {t('Benefits of Partnering with Alma')}
          </h1>

          <p className="mt-5 text-center mx-auto w-11/12 sm:w-10/12 md:w-full font-intertight font-extralight text-sm sm:text-base md:text-lg lg:text-xl text-darkstate">
            {t('Enjoy the following benefits as our partner')}
          </p>

          <div className="w-9/12 py-14 md:py-76 mx-auto flex items-center justify-center">
            <div className="grid gap-5 grid-cols-2 lg:grid-cols-4">
              <div className='col-span-2 lg:col-start-1 lg:row-start-2'>
                <div className='grid grid-cols-1 gap-9'>
                  <div className='p-2 flex bg-battleshipgrey w-full lg:w-10/12 rounded-xl'>
                    <img className='my-auto w-2/12 h-55' src={ticksvg} alt='tick Pic' />
                    <h1 className='font-intertight my-auto w-[304px] font-normal leading-[19.37px] ml-2.5 md:ml-0 text-xs md:text-base text-offwhite'>
                      {t('Increase your exposure')}
                    </h1>
                  </div>
                  <div className='p-2 flex bg-darkstate w-full lg:w-10/12 rounded-xl'>
                    <img className='my-auto w-2/12 h-55' src={ticksvg} alt='tick Pic' />
                    <h1 className='font-intertight my-auto w-[304px] h-[43px] font-normal leading-[19.37px] ml-2.5 md:ml-0 text-xs md:text-base text-offwhite'>
                      {t('Attract new members and gain additional income')}
                    </h1>
                  </div>
                  <div className='p-2 font-intertight flex bg-onyx w-full lg:w-10/12 rounded-xl'>
                    <img className='my-auto w-2/12 h-55' src={ticksvg} alt='tick Pic' />
                    <p className='text-intertight my-auto w-[304px] h-[43px] font-normal leading-[19.37px] ml-2.5 md:ml-0 text-xs md:text-base text-offwhite'>
                      {t('Simplify administration processes such as booking and management')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-span-2  h-3/6 md:h-[501px] md:col-span-1 shadow-3xl rounded-xl mt-10 lg:mt-0 md:block lg:row-start-1 lg:row-end-5">
                <img src={mobile} className='md:w-[231px] md:h-[501px]' alt='mobile pic' />
              </div>
              <div className='col-span-2  h-3/6 md:h-[501px] md:col-span-1 shadow-3xl rounded-xl mt-10 lg:mt-0 md:block lg:row-start-2 lg:row-end-6'>
                <img src={homemobile} className=' mx-auto md:w-[231px] md:h-[501px]' alt='Home mobile' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Benefits;
