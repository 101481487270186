import Navbar from "../src/header/index";
// import Footer from "./footer/index";
import Parteners from "./pages/partners/index";
function App() {
  return (
    <>
    
      <Navbar />
      <Parteners/>
     

    </>
  );
}

export default App;
