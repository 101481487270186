import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

function App() {
    const { t } = useTranslation(); // Use the translation hook
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setIsModalVisible(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (loading) return; // Prevent submission if already loading

        setLoading(true); // Set loading to true when starting submission

        try {
            const response = await axios.post('https://almaxcollective.com/fitnessbk/public/api/early-access', { email });
            if (response.data.success) {
                toast.success(t('Email added successfully!'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                // Close the modal after showing the success toast
                setTimeout(() => {
                    setIsModalVisible(false);
                }, 1000); // Close after 1 second delay
            } else {
                toast.error(response.data.message || t('Failed to add email.'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } catch (error) {
            toast.dismiss(); 
            if (error.response && error.response.status === 409) {
                toast.error(t('Email already exists!'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                toast.error(t('Email already exists!'), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <div className="flex items-center justify-center">
            <ToastContainer />
            {isModalVisible && (
                <div className="fixed z-100 inset-0 px-[10px] bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-starwhite p-8 rounded-lg max-w-md mx-auto relative">
                        <button onClick={handleClose} className="absolute top-4 right-4 text-black text-xl">&times;</button>
                        <h1 className="text-3xl font-bold text-center mb-4">{t('Get Early Access')}</h1>
                        <p className="text-center mb-6">{t('Be among the first to book fitness classes across Greece. We\'ll notify you as soon as the app launches!')}</p>
                        <form className="text-center" onSubmit={handleSubmit}>
                            <input
                                className="border-b-2 bg-starwhite border-black w-full mb-6 p-2 focus:outline-none"
                                placeholder={t('Your email address')}
                                type="email"
                                id="email"
                                name="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            <button
                                className="bg-black text-white py-2 px-4 rounded w-full"
                                type="submit"
                                disabled={loading} // Disable button while loading
                            >
                                {loading ? t('Submitting...') : t('Submit')}
                            </button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}

export default App;
