import Parteners from "./Parteners";
import Popup from "../popup/index"
import Beniftsofparteners from "./beniftsofparteners";
function main () {
    return (
        <>  
                <Parteners />
                <Popup/>
                <Beniftsofparteners/>
        </>
      );
}

export default main ;