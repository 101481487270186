import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// SVG icons for languages
const languageIcons = {
  en: (
    <svg width="24" height="24" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg" fill="#000000">
      <path fill="#00247D" d="M0 9.059V13h5.628zM4.664 31H13v-5.837zM23 25.164V31h8.335zM0 23v3.941L5.63 23zM31.337 5H23v5.837zM36 26.942V23h-5.631zM36 13V9.059L30.371 13zM13 5H4.664L13 10.837z"></path>
      <path fill="#CF1B2B" d="M25.14 23l9.712 6.801a3.977 3.977 0 0 0 .99-1.749L28.627 23H25.14zM13 23h-2.141l-9.711 6.8c.521.53 1.189.909 1.938 1.085L13 23.943V23zm10-10h2.141l9.711-6.8a3.988 3.988 0 0 0-1.937-1.085L23 12.057V13zm-12.141 0L1.148 6.2a3.994 3.994 0 0 0-.991 1.749L7.372 13h3.487z"></path>
      <path fill="#EEE" d="M36 21H21v10h2v-5.836L31.335 31H32a3.99 3.99 0 0 0 2.852-1.199L25.14 23h3.487l7.215 5.052c.093-.337.158-.686.158-1.052v-.058L30.369 23H36v-2zM0 21v2h5.63L0 26.941V27c0 1.091.439 2.078 1.148 2.8l9.711-6.8H13v.943l-9.914 6.941c.294.07.598.116.914.116h.664L13 25.163V31h2V21H0zM36 9a3.983 3.983 0 0 0-1.148-2.8L25.141 13H23v-.943l9.915-6.942A4.001 4.001 0 0 0 32 5h-.663L23 10.837V5h-2v10h15v-2h-5.629L36 9.059V9zM13 5v5.837L4.664 5H4a3.985 3.985 0 0 0-2.852 1.2l9.711 6.8H7.372L.157 7.949A3.968 3.968 0 0 0 0 9v.059L5.628 13H0v2h15V5h-2z"></path>
      <path fill="#CF1B2B" d="M21 15V5h-6v10H0v6h15v10h6V21h15v-6z"></path>
    </svg>
  )
  ,
  gr: (
    <svg width="24" height="24" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M56 14H32v6h27.5c-.9-2.1-2.1-4.2-3.5-6" fill="#428bc1"/>
      <g fill="#f9f9f9">
        <path d="M32 14h24c-1.7-2.3-3.7-4.3-6-6H32v6"/>
        <path d="M59.5 20H32v6h29.4c-.4-2.1-1.1-4.1-1.9-6"/>
      </g>
      <path d="M4.5 44h55c.8-1.9 1.5-3.9 1.9-6H2.6c.4 2.1 1.1 4.1 1.9 6" fill="#428bc1"/>
      <path d="M8 50h48c1.4-1.8 2.6-3.9 3.5-6h-55c.9 2.1 2.1 4.2 3.5 6" fill="#f9f9f9"/>
      <path d="M8 50c1.7 2.3 3.7 4.3 6 6h36c2.3-1.7 4.3-3.7 6-6H8z" fill="#428bc1"/>
      <g fill="#f9f9f9">
        <path d="M14 56c5 3.8 11.2 6 18 6s13-2.2 18-6H14"/>
        <path d="M20 32V20h12v-6H20V4.5c-2.1.9-4.2 2.1-6 3.5v6H8c-1.4 1.8-2.6 3.9-3.5 6H14v12H2c0 2.1.2 4.1.6 6h58.8c.4-1.9.6-3.9.6-6H20"/>
      </g>
      <g fill="#428bc1">
        <path d="M61.4 26H32v-6H20v12h42c0-2.1-.2-4.1-.6-6"/>
        <path d="M32 2c-4.3 0-8.3.9-12 2.5V14h12V8h18c-5-3.8-11.2-6-18-6"/>
        <path d="M14 14V8c-2.3 1.7-4.3 3.7-6 6h6"/>
        <path d="M4.5 20C2.9 23.7 2 27.7 2 32h12V20H4.5z"/>
      </g>
    </svg>
  ),
};

function LanguageDropdown() {
  const { i18n } = useTranslation(); 
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [isOpen, setIsOpen] = useState(false);

  // Update selectedLanguage on language change or after refresh
  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleSelectLanguage = (lang) => {
    setSelectedLanguage(lang);
    i18n.changeLanguage(lang); 
    setIsOpen(false);
  };

  return (
    <div className="relative text-left">
      <button
        onClick={handleToggle}
        className="flex items-center px-2 py-2 rounded-md bg-blackish text-white"
      >
        {languageIcons[selectedLanguage]}
        <span className="ml-2 capitalize">
          {selectedLanguage} {/* Display 'en' or 'gr' here */}
        </span>
        <svg
          className="ml-2 w-4 h-4"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M19 9l-7 7-7-7" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>

      {isOpen && (
        <div className="absolute right-0 w-40 mt-2 bg-blackish rounded-md z-10">
          {Object.keys(languageIcons).map((lang) => (
            <button
              key={lang}
              onClick={() => handleSelectLanguage(lang)}
              className="flex items-center px-4 py-2 w-full text-left text-white hover:bg-gray-700"
            >
              {languageIcons[lang]}
              <span className="ml-2 capitalize">
                {lang === 'en' ? 'English' : 'Greek'} {/* Full names in the dropdown */}
              </span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default LanguageDropdown;
