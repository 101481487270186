import React from 'react';
import logo from '../assets/images/logo.png';
import Almax from '../assets/images/Almax.png';
import LanguageDropdown from '../component/LanguageDrop';
import { useTranslation } from 'react-i18next';

function Main() {
  const { t } = useTranslation(); // Use the translation hook

  return (
    <div>
      <div className="bg-blackish z-20 w-full px-2 md:px-8 lg:px-[94px]  fixed top-0 h-16 flex items-center justify-between">
        <div className="flex items-center">
          <img className="w-10 sm:w-12" src={logo} alt="logo" />
          <img className="w-20 sm:w-24" src={Almax} alt="Almax" />
        </div>

        {/* Navigation Menu */}
        <div className="flex items-center text-offwhite font-semibold font-inter  md:space-x-6 p-1 md:p-0">
          {/* Language Dropdown */}
          <LanguageDropdown />

          {/* Navigation Links */}
          <a href="/" className="hover:text-gray-300 font-semibold">
            {t('Partners')}
          </a>
        </div>
      </div>
    </div>
  );
}

export default Main;
