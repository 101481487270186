import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

function ScndForm({ formData, handleInputChange, handleSubmit }) {
  const { t } = useTranslation();
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    let formErrors = {};

    if (!formData.genre) formErrors.genre = t("Genre is required");
    if (!formData.no_of_locations || formData.no_of_locations <= 0)
      formErrors.no_of_locations = t(
        "Number of locations must be greater than 0"
      );
    if (
      !formData.no_of_classes_per_location ||
      formData.no_of_classes_per_location <= 0
    )
      formErrors.no_of_classes_per_location = t(
        "Number of classes per location must be greater than 0"
      );
    if (!formData.capacity || formData.capacity <= 0)
      formErrors.capacity = t("Capacity must be greater than 0");
    if (!formData.amount || formData.amount <= 0)
      formErrors.amount = t("Amount must be greater than 0");
    if (!formData.booking_software)
      formErrors.booking_software = t("Booking software is required");

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;

    if (validateForm()) {
      setLoading(true);

      try {
        const response = await handleSubmit(); // Get the response here
        toast.dismiss();
        if (response.success) {
          toast.success(response.message || t("Form submitted successfully!"), {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error(
            response.message || t("Submission failed. Please try again."),
            {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
        }
      } catch (error) {
        toast.error(t("An error occurred. Please try again later."), {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  // const handleFormSubmit = async (e) => {
  //   e.preventDefault();
  //   if (loading) return;

  //   if (validateForm()) {
  //     setLoading(true);

  //     try {
  //       const response = await handleSubmit();
  //       toast.dismiss();
  //       if (response.success) {
  //         toast.success(t("Form submitted successfully!"), {
  //           position: "top-right",
  //           autoClose: 2000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //         setTimeout(() => {
  //           window.location.reload();
  //         }, 2000);
  //       } else {
  //         toast.error(t("Submission failed. Please try again."), {
  //           position: "top-right",
  //           autoClose: 2000,
  //           hideProgressBar: false,
  //           closeOnClick: true,
  //           pauseOnHover: true,
  //           draggable: true,
  //           progress: undefined,
  //           theme: "colored",
  //         });
  //       }
  //     } catch (error) {
  //       toast.error(t("An error occurred. Please try again later."), {
  //         position: "top-right",
  //         autoClose: 2000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "colored",
  //       });
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  // };

  // Custom CSS for the toast container
  const toastStyles = {
    width: "90%", // Default width
    maxWidth: "320px", // Max width on smaller screens
    margin: "0 auto", // Center it horizontally
  };

  // Mobile specific styles using TailwindCSS or inline
  const mobileStyles = `
  @media (max-width: 640px) {
    .Toastify__toast-container {
      width: 90% !important; /* Shrink width for mobile */
      max-width: 320px !important; /* Set a max width */
    }
  }
`;

  return (
    <div className="py-10 bg-white rounded-xl shadow-2xl w-full mt-10">
      {/* Include the custom styles in a <style> tag */}
      <style>{mobileStyles}</style>
      {/* ToastContainer with inline styling */}
      <ToastContainer
        className="Toastify__toast-container"
        style={toastStyles}
      />

      <div className="grid text-left items-center justify-center mx-auto w-10/12 gap-x-1 gap-y-4 grid-cols-1 lg:grid-cols-2">
        <div>
          <h1 className="font-intertight font-light text-base">
            {t("Primary Activity Genre")}
          </h1>
          <input
            type="text"
            name="genre"
            value={formData.genre}
            onChange={handleInputChange}
            placeholder={t("Genre")}
            className="w-full mt-2.5 p-2 border border-gray-300 rounded-md"
          />
          {errors.genre && (
            <span className="text-red-500 text-sm">{errors.genre}</span>
          )}
        </div>
        <div>
          <h1 className="font-intertight font-light text-base">
            {t("How many locations do you have?")}
          </h1>
          <input
            type="number"
            name="no_of_locations"
            value={formData.no_of_locations}
            onChange={handleInputChange}
            placeholder={t("Number of Locations")}
            className="w-full mt-2.5 p-2 border border-gray-300 rounded-md"
          />
          {errors.no_of_locations && (
            <span className="text-red-500 text-sm">
              {errors.no_of_locations}
            </span>
          )}
        </div>
        <div className="col-span-1 md:col-span-2">
          <h1 className="font-intertight font-light text-base">
            {t(
              "How many classes per week do you offer on average per location?"
            )}
          </h1>
          <input
            type="number"
            name="no_of_classes_per_location"
            value={formData.no_of_classes_per_location}
            onChange={handleInputChange}
            placeholder={t("Number of Classes per Location")}
            className="w-full mt-2.5 p-2 border border-gray-300 rounded-md"
          />
          {errors.no_of_classes_per_location && (
            <span className="text-red-500 text-sm">
              {errors.no_of_classes_per_location}
            </span>
          )}
        </div>
        <div className="col-span-1 md:col-span-2">
          <h1 className="font-intertight font-light text-base">
            {t("What is your class or gym capacity?")}
          </h1>
          <input
            type="number"
            name="capacity"
            value={formData.capacity}
            onChange={handleInputChange}
            placeholder={t("Capacity")}
            className="w-full mt-2.5 p-2 border border-gray-300 rounded-md"
          />
          {errors.capacity && (
            <span className="text-red-500 text-sm">{errors.capacity}</span>
          )}
        </div>
        <div className="col-span-1 md:col-span-2">
          <h1 className="font-intertight font-light text-base">
            {t("On average, how much do you charge per spot?")}
          </h1>
          <input
            type="number"
            name="amount"
            value={formData.amount}
            onChange={handleInputChange}
            placeholder={t("Amount")}
            className="w-full mt-2.5 p-2 border border-gray-300 rounded-md"
          />
          {errors.amount && (
            <span className="text-red-500 text-sm">{errors.amount}</span>
          )}
        </div>
        <div className="col-span-1 md:col-span-2">
          <h1 className="font-intertight font-light text-base">
            {t("Do you use a booking software? If yes, which one?")}
          </h1>
          <input
            type="text"
            name="booking_software"
            value={formData.booking_software}
            onChange={handleInputChange}
            placeholder={t("Booking Software")}
            className="w-full mt-2.5 p-2 border border-gray-300 rounded-md"
          />
          {errors.booking_software && (
            <span className="text-red-500 text-sm">
              {errors.booking_software}
            </span>
          )}
        </div>
      </div>
      <button
        type="button"
        className="bg-richblack rounded-[53px] text-white font-intertight font-light text-base mt-65 w-6/12 p-2 sm:w-[306px] sm:h-[48.95px]"
        onClick={handleFormSubmit}
        disabled={loading}
      >
        {loading ? t("Submitting...") : t("Become a Partner")}
      </button>
    </div>
  );
}

export default ScndForm;
