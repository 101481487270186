import React, { useState } from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import CityDropdown from "../../component/reactDropDown";
import ScndForm from "./SecndForm";
import { useTranslation } from 'react-i18next';
import '../../component/ChangeLanguage';
import 'react-toastify/dist/ReactToastify.css';

function Form() {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    business_name: "",
    contact_person: "",
    email: "",
    website: "",
    phone: "",
    business_address: "",
    city: "",
    genre: "",
    no_of_locations: "",
    no_of_classes_per_location: "",
    capacity: "",
    amount: "",
    booking_software: "",
  });

  const [errors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(1);

  const validateForm = () => {
    let formErrors = {};
    // Validation for Step 1
    if (!formData.business_name) formErrors.business_name = t("businessNameRequired");
    if (!formData.contact_person) formErrors.contact_person = t("contactPersonRequired");
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) formErrors.email = t("validEmailRequired");
    if (!formData.phone || isNaN(formData.phone)) formErrors.phone = t("phoneRequired");
    if (!formData.business_address) formErrors.business_address = t("businessAddressRequired");
    if (!formData.city) formErrors.city = t("cityRequired");

    // Validation for Step 2
    if (currentStep === 2) {
      if (!formData.genre) formErrors.genre = t("genreRequired");
      if (!formData.no_of_locations || formData.no_of_locations <= 0) formErrors.no_of_locations = t("noOfLocationsRequired");
      if (!formData.no_of_classes_per_location || formData.no_of_classes_per_location <= 0) formErrors.no_of_classes_per_location = t("noOfClassesPerLocationRequired");
      if (!formData.capacity || formData.capacity <= 0) formErrors.capacity = t("capacityRequired");
      if (!formData.amount || formData.amount <= 0) formErrors.amount = t("amountRequired");
      if (!formData.booking_software) formErrors.booking_software = t("bookingSoftwareRequired");
    }
    
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleNext = () => {
    if (validateForm()) setCurrentStep(2);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCityChange = (value) => {
    setFormData({ ...formData, city: value });
  };


  const handleSubmit = async () => {
    try {
      const response = await axios.post("https://almaxcollective.com/fitnessbk/public/api/create-partner-request", formData);
      if (response.data.success) {
        return response.data; // Return the data here
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      if (error.response) {
        // Server responded with a status code outside the 2xx range
        console.error('Error Response:', error.response.data);
        console.error('Status Code:', error.response.status);
        return { success: false, message: error.response.data.message || 'An error occurred on the server' };
      } else if (error.request) {
        // Request was made but no response was received
        console.error('No Response received:', error.request);
        return { success: false, message: 'No response received from the server' };
      } else {
        // Something else caused the error
        console.error('Error setting up request:', error.message);
        return { success: false, message: 'Error setting up request: ' + error.message };
      }
    }
  };
  
  // const handleSubmit = async () => {
  //   // try {
  //   //   const response = await axios.post("https://almaxcollective.com/fitnessbk/public/api/create-partner-request", formData);
  //   //   if (response.data.success) {
  //   //     console.log('Form submitted successfully');
  //   //   } else {
  //   //     console.error('Submission failed:', response.data.message);
  //   //   }
  //   // } catch (error) {
  //   //   console.error('An error occurred:', error.response ? error.response.data.message : "An unknown error occurred");
  //   // }

  //   try {
  //     const response = await axios.post("https://almaxcollective.com/fitnessbk/public/api/create-partner-request", formData);
  //     if (response.data.success) {
  //       console.log('Form submitted successfully', response.data);
  //     } else {
  //       console.error('Submission failed:', response.data.message);
  //     }
  //   } catch (error) {
  //     if (error.response) {
  //       // Server responded with a status code outside the 2xx range
  //       console.error('Error Response:', error.response.data);
  //       console.error('Status Code:', error.response.status);
  //     } else if (error.request) {
  //       // Request was made but no response was received
  //       console.error('No Response received:', error.request);
  //     } else {
  //       // Something else caused the error
  //       console.error('Error setting up request:', error.message);
  //     }
  //   }
    
  // };
  return (
    <>
      <ToastContainer />
      {currentStep === 1 ? (
        <div className="py-10 bg-white rounded-xl shadow-2xl w-full mt-59">
          <div className="grid text-left items-start justify-center mx-auto w-10/12 gap-x-1 gap-y-4 grid-cols-1 lg:grid-cols-2">
            <div className="flex flex-col">
              <label className="font-intertight font-light text-base">{t("businessName")}</label>
              <input
                type="text"
                name="business_name"
                value={formData.business_name}
                onChange={handleInputChange}
                placeholder={t("businessName")}
                className="w-full mt-2.5 p-2 border border-gray-300 rounded-md"
              />
              {errors.business_name && <span className="text-red-500 text-sm mt-1">{errors.business_name}</span>}
            </div>

            <div className="flex flex-col">
              <label className="font-intertight font-light text-base">{t("contactPerson")}</label>
              <input
                type="text"
                name="contact_person"
                value={formData.contact_person}
                onChange={handleInputChange}
                placeholder={t("contactPerson")}
                className="w-full mt-2.5 p-2 border border-gray-300 rounded-md"
              />
              {errors.contact_person && <span className="text-red-500 text-sm mt-1">{errors.contact_person}</span>}
            </div>

            <div className="flex flex-col">
              <label className="font-intertight font-light text-base">{t("email")}</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder={t("email")}
                className="w-full mt-2.5 p-2 border border-gray-300 rounded-md"
              />
              {errors.email && <span className="text-red-500 text-sm mt-1">{errors.email}</span>}
            </div>

            <div className="flex flex-col">
              <label className="font-intertight font-light text-base">{t("website")}</label>
              <input
                type="text"
                name="website"
                value={formData.website}
                onChange={handleInputChange}
                placeholder={t("website")}
                className="w-full mt-2.5 p-2 border border-gray-300 rounded-md"
              />
              {errors.website && <span className="text-red-500 text-sm mt-1">{errors.website}</span>}
            </div>

            <div className="flex flex-col">
              <label className="font-intertight font-light text-base">{t("phone")}</label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder={t("phone")}
                className="w-full mt-2.5 p-2 border border-gray-300 rounded-md"
              />
              {errors.phone && <span className="text-red-500 text-sm mt-1">{errors.phone}</span>}
            </div>

            <div className="flex flex-col">
              <label className="font-intertight font-light text-base">{t("businessAddress")}</label>
              <input
                type="text"
                name="business_address"
                value={formData.business_address}
                onChange={handleInputChange}
                placeholder={t("businessAddress")}
                className="w-full mt-2.5 p-2 border border-gray-300 rounded-md"
              />
              {errors.business_address && <span className="text-red-500 text-sm mt-1">{errors.business_address}</span>}
            </div>

            <div className="col-span-1 md:col-span-2 flex flex-col">
              <label className="font-intertight font-light text-base">{t("In which city is your business located?")}</label>
              <CityDropdown selectedCity={formData.city} handleCityChange={handleCityChange} />
              {errors.city && <span className="text-red-500 text-sm mt-1">{errors.city}</span>}
            </div>
          </div>
          <button
            className="bg-richblack rounded-[53px] text-white font-intertight font-light text-base mt-8 w-7/12 p-2 sm:p-0 sm:w-[306px] sm:h-[48.95px]"
            onClick={handleNext}
          >
            {t("next")}
          </button>
        </div>
      ) : (
        <ScndForm formData={formData} handleInputChange={handleInputChange} handleSubmit={handleSubmit} />
      )}
    </>
  );
}

export default Form;
